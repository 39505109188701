import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card";

import { readState } from "@/__main__/app-state.mjs";
import { updateRoute } from "@/__main__/router.mjs";
import FortniteLiveTile from "@/game-fortnite/components/LiveTile";
import MatchHistoryHeader from "@/game-fortnite/components/MatchHistoryHeader.jsx";
import MatchTileContent from "@/game-fortnite/components/MatchTile.jsx";
import ProfileTopWeapons from "@/game-fortnite/components/ProfileTopWeapons.jsx";
import { Modes, Queues, Seasons } from "@/game-fortnite/components/Selects";
import {
  FORTNITE_PLAYLISTS,
  FORTNITE_PLAYLISTS_SYMBOLS,
} from "@/game-fortnite/constants/playlists.mjs";
import {
  FORTNITE_RANKS,
  FORTNITE_RANKS_SYMBOLS,
} from "@/game-fortnite/constants/ranks.mjs";
import SearchParamsFnEnum from "@/game-fortnite/constants/searchParams.mjs";
import type { PlayerMatches } from "@/game-fortnite/models/matchlist.mjs";
import type { PlayerRanks } from "@/game-fortnite/models/player-ranks.mjs";
import type { PlayerSummary } from "@/game-fortnite/models/player-summaries.mjs";
import {
  getProfilePageParams,
  getProfilePageParamsStr,
} from "@/game-fortnite/utils/get-params.mjs";
import Static from "@/game-fortnite/utils/static.mjs";
import SkeletonMatchIcon from "@/inline-assets/skeleton-match.svg";
import Container from "@/shared/ContentContainer.jsx";
import FilterBar from "@/shared/FilterBar.jsx";
import SharedProfile, {
  MatchList,
  MatchTile,
  ShowMoreFooter,
} from "@/shared/Profile.jsx";
import { ProfileColumn, ProfileMatchlist } from "@/shared/Profile.style.jsx";
import ProfileRank from "@/shared/ProfileRank.jsx";
import HeadShotStats from "@/shared-fps/HeadShotStats.jsx";
import { useTransientRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const DEFAULT_MATCHES_LIMIT = 20;

export default function ProfileOverview({ profileId }: { profileId: string }) {
  const state = useSnapshot(readState);
  const { t } = useTranslation();
  const {
    currentPath,
    searchParams,
    state: { transient },
  } = useTransientRoute();

  const { mode, season } = getProfilePageParams(searchParams, {
    season: state.fortnite.seasons[0]?.id,
  });
  const key = getProfilePageParamsStr(searchParams);
  // @ts-ignore
  const matchList: PlayerMatches | Error | undefined =
    state.fortnite.playerMatches[profileId]?.[season]?.[key];
  // @ts-ignore
  const rawRanks: PlayerRanks | Error | undefined =
    state.fortnite.playerRanks[profileId]?.[season];
  const $matches = state.fortnite.matches;
  const matches: PlayerMatches = useMemo(() => {
    if (!matchList || matchList instanceof Error) return [];
    return matchList.sort(
      (a, b) =>
        new Date(b.match.startedAt).getTime() -
        new Date(a.match.startedAt).getTime(),
    );
  }, [matchList]);

  const isEndOfMatchList = useMemo(() => {
    const page = Math.max(1, Math.ceil(matches.length / DEFAULT_MATCHES_LIMIT));
    return matches.length < DEFAULT_MATCHES_LIMIT * page;
  }, [matches.length]);

  const loadMoreMatches = useCallback(() => {
    updateRoute(currentPath, searchParams, {
      transient: {
        offset: matches.length,
        prevOffset: transient?.offset || 0,
      },
    });
  }, [currentPath, matches.length, searchParams, transient]);

  const getWinsLosses = useCallback(
    (mode: string) => {
      const modified = new URLSearchParams(searchParams.toString());
      modified.set(SearchParamsFnEnum.Mode, mode);
      // @ts-ignore
      const playerSummary: PlayerSummary | Error | undefined =
        state.fortnite.profileSummaries[profileId]?.[season]?.[
          getProfilePageParamsStr(modified)
        ];
      const summary: PlayerSummary | undefined =
        playerSummary && !(playerSummary instanceof Error)
          ? playerSummary
          : null;
      const wins: number = summary?.wins ?? 0;
      const losses: number = (summary?.matches ?? 0) - wins;
      return { wins, losses };
    },
    [profileId, searchParams, season, state.fortnite.profileSummaries],
  );

  const { rank, promotionProgress, title, wins, losses } = useMemo(() => {
    const isAllPlaylist =
      mode.key ===
      FORTNITE_PLAYLISTS[FORTNITE_PLAYLISTS_SYMBOLS.fortniteAllPlaylists].key;
    const playerRanks =
      rawRanks && !(rawRanks instanceof Error) ? rawRanks : [];
    const rankingType = mode.isZeroBuild ? "ranked-zb" : "ranked-br";
    const playerRank = isAllPlaylist
      ? playerRanks.toSorted((a, b) => b.rank - a.rank)[0]
      : playerRanks.find((r) => r.track.rankingType === rankingType);
    const rankSymbol = playerRank
      ? Object.getOwnPropertySymbols(FORTNITE_RANKS).find(
          (symbol) => FORTNITE_RANKS[symbol].rank === playerRank.rank,
        )
      : FORTNITE_RANKS_SYMBOLS.unranked;

    if ((!mode.isRanked && !isAllPlaylist) || !playerRank) {
      const { wins, losses } = getWinsLosses(mode.key);
      return {
        rank: FORTNITE_RANKS[FORTNITE_RANKS_SYMBOLS.unranked],
        promotionProgress: 0,
        title: t(...mode.t),
        wins,
        losses,
      };
    }

    const rankMode =
      playerRank.track.rankingType === "ranked-zb"
        ? FORTNITE_PLAYLISTS[FORTNITE_PLAYLISTS_SYMBOLS.rankedZeroBuild]
        : FORTNITE_PLAYLISTS[FORTNITE_PLAYLISTS_SYMBOLS.rankedBattleRoyale];
    const { wins, losses } = getWinsLosses(rankMode.key);
    return {
      rank: FORTNITE_RANKS[rankSymbol],
      promotionProgress: playerRank?.promotionProgress || 0,
      title: t(...rankMode.t),
      wins,
      losses,
    };
  }, [
    getWinsLosses,
    mode.isRanked,
    mode.isZeroBuild,
    mode.key,
    mode.t,
    rawRanks,
    t,
  ]);
  const { lastNStats, hitTrend } = useMemo<{
    lastNStats: {
      headshots: number;
      bodyshots: number;
      chestshots: number;
      stomachshots: number;
      legshots: number;
    };
    hitTrend: Array<{
      index: number;
      hsPercent: number;
      lastNAvgHS: number;
      imgUrl: string;
    }>;
  }>(() => {
    const lastNStats = {
      headshots: 0,
      bodyshots: 0,
      chestshots: 0,
      stomachshots: 0,
      legshots: 0,
    };
    const hitTrend = [];
    for (let i = 0; i < DEFAULT_MATCHES_LIMIT && i < matches.length; i += 1) {
      lastNStats.headshots += matches[i].totalHeadshots;
      lastNStats.bodyshots += matches[i].totalHits - matches[i].totalHeadshots;
      hitTrend.push({
        index: i,
        hsPercent: matches[i].headshotPercentage,
        lastNAvgHS: matches[i].headshotPercentageHist,
        imgUrl: Static.getProfileImage(matches[i].match.gameId),
      });
    }
    return { lastNStats, hitTrend };
  }, [matches]);

  return (
    <>
      <Container>
        <FilterBar hiddenItems={undefined} className={undefined}>
          <Modes mode={mode.key} />
          <Queues />
          <Seasons />
        </FilterBar>
      </Container>
      <Container>
        <SharedProfile>
          <ProfileColumn className="flex column gap-sp-4 sidebar">
            <ProfileRank
              title={title}
              image={Static.getRankIcon(rank.key)}
              imageComponent={
                <div
                  style={{
                    height: 80,
                    width: 80,
                    background: "var(--game-accent-color)",
                    borderRadius: "var(--br-xl)",
                  }}
                />
              }
              name={t(...rank.t)}
              points={undefined}
              percentFill={promotionProgress / 100}
              color={rank.color}
              wins={wins}
              losses={losses}
              belowTitle={undefined}
              below={undefined}
              winStreakBadgeComponent={undefined}
            />
            <Card padding="0">
              <HeadShotStats
                lastNStats={lastNStats}
                matches={hitTrend}
                hideHits
                hiddenLegshots
              />
            </Card>
            <ProfileTopWeapons profileId={profileId} />
          </ProfileColumn>
          <ProfileColumn className="main">
            <MatchHistoryHeader matches={matches} />
            <FortniteLiveTile />
            <ProfileMatchlist>
              {matches.length > 0 ? (
                <MatchList matchList={matches}>
                  {matches.map((match) => {
                    const gameId = match.match.gameId;
                    const fullMatch = $matches[gameId];
                    return (
                      <MatchTile id={gameId} key={gameId} match={fullMatch}>
                        {Boolean(
                          !(fullMatch instanceof Error) && fullMatch,
                        ) && (
                          <MatchTileContent
                            gameId={gameId}
                            profileId={profileId}
                          />
                        )}
                      </MatchTile>
                    );
                  })}
                </MatchList>
              ) : (
                <Card className="flex align-center column gap-6">
                  <SkeletonMatchIcon style={{ fill: "transparent" }} />
                  <div className="flex align-center column">
                    <div className="type-h5">
                      {t("fortnite:suggestions.noMatches", "No Matches Found")}
                    </div>
                    <div className="type-body1 shade1">
                      {t(
                        "fortnite:suggestions.playOneGame",
                        "You should play at least one match to get match history feed.",
                      )}
                    </div>
                  </div>
                </Card>
              )}
              {matches.length ? (
                <ShowMoreFooter
                  hasMore={!isEndOfMatchList}
                  setShowMore={() => loadMoreMatches()}
                />
              ) : null}
            </ProfileMatchlist>
          </ProfileColumn>
        </SharedProfile>
      </Container>
    </>
  );
}
